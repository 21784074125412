/* eslint-disable import/max-dependencies */
import { ArrowDown as _ArrowDownThin } from "@styled-icons/bootstrap/ArrowDown"
import { ArrowUp as _ArrowUpThin } from "@styled-icons/bootstrap/ArrowUp"
import { ChatLeftFill as _ChatLeftFill } from "@styled-icons/bootstrap/ChatLeftFill"
import { CircleFill as CrclFll } from "@styled-icons/bootstrap/CircleFill"
import { ClipboardPlus as _ClipboardPlus } from "@styled-icons/bootstrap/ClipboardPlus"
import { Dash as _Dash } from "@styled-icons/bootstrap/Dash"
import { Filter as _Filter } from "@styled-icons/bootstrap/Filter"
import { Key as _Key } from "@styled-icons/bootstrap/Key"
import { PinAngle as _PinAngle } from "@styled-icons/bootstrap/PinAngle"
import { PinFill as _PinFill } from "@styled-icons/bootstrap/PinFill"
import { ShopWindow as _ShopWindow } from "@styled-icons/bootstrap/ShopWindow"
import { Star as _Star } from "@styled-icons/bootstrap/Star"
import { StarFill as _StarFill } from "@styled-icons/bootstrap/StarFill"
import { Paypal } from "@styled-icons/boxicons-logos/Paypal"
import { Cabinet as Cbnt } from "@styled-icons/boxicons-regular"
import { ChevronDown as ChvrnDown } from "@styled-icons/boxicons-regular/ChevronDown"
import { ChevronLeft as ChvrnLeft } from "@styled-icons/boxicons-regular/ChevronLeft"
import { ChevronRight as ChvrnRight } from "@styled-icons/boxicons-regular/ChevronRight"
import { HeartCircle as _HeartCircle } from "@styled-icons/boxicons-regular/HeartCircle"
import { InfoCircle as InfCrcl } from "@styled-icons/boxicons-regular/InfoCircle"
import { LoaderAlt as _Loader, LoaderAlt as _LoaderAlt } from "@styled-icons/boxicons-regular/LoaderAlt"
import { MinusCircle as MnsCrcl } from "@styled-icons/boxicons-regular/MinusCircle"
import { PlusCircle as PlsCrcl } from "@styled-icons/boxicons-regular/PlusCircle"
import { Reset as _Reset } from "@styled-icons/boxicons-regular/Reset"
import { Save as _Save } from "@styled-icons/boxicons-regular/Save"
import { Search as _Search } from "@styled-icons/boxicons-regular/Search"
import { Stopwatch as _Stopwatch } from "@styled-icons/boxicons-regular/Stopwatch"
import { ToggleLeft as _ToggleLeft } from "@styled-icons/boxicons-regular/ToggleLeft"
import { ToggleRight as _ToggleRight } from "@styled-icons/boxicons-regular/ToggleRight"
import { BellOff as _BellOff } from "@styled-icons/boxicons-solid/BellOff"
import { BellRing as _Bell } from "@styled-icons/boxicons-solid/BellRing"
import { Calendar as Cldr } from "@styled-icons/boxicons-solid/Calendar"
import { ChevronLeftCircle as _ChevronLeftCircle } from "@styled-icons/boxicons-solid/ChevronLeftCircle"
import { ChevronRightCircle as _ChevronRightCircle } from "@styled-icons/boxicons-solid/ChevronRightCircle"
import { Circle as Crcl } from "@styled-icons/boxicons-solid/Circle"
import { CreditCard } from "@styled-icons/boxicons-solid/CreditCard"
import { Dashboard as Dshbrd } from "@styled-icons/boxicons-solid/Dashboard"
import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow"
import { FoodMenu as FdMenu } from "@styled-icons/boxicons-solid/FoodMenu"
import { HelpCircle as _HelpCircle } from "@styled-icons/boxicons-solid/HelpCircle"
import { Home as Hme } from "@styled-icons/boxicons-solid/Home"
import { ImageAdd as ImgAdd } from "@styled-icons/boxicons-solid/ImageAdd"
import { LeftArrow as LftArrow } from "@styled-icons/boxicons-solid/LeftArrow"
import { Pencil as Pncl } from "@styled-icons/boxicons-solid/Pencil"
import { Rename as _Rename } from "@styled-icons/boxicons-solid/Rename"
import { ShoppingBags as _ShoppingBags } from "@styled-icons/boxicons-solid/ShoppingBags"
import { Store as Str } from "@styled-icons/boxicons-solid/Store"
import { Trash } from "@styled-icons/boxicons-solid/Trash"
import { UpArrow } from "@styled-icons/boxicons-solid/UpArrow"
import { User as _User } from "@styled-icons/boxicons-solid/User"
import { Grid as Grd } from "@styled-icons/entypo/Grid"
import { LogOut } from "@styled-icons/entypo/LogOut"
import { PaperPlane as _PaperPlane } from "@styled-icons/entypo/PaperPlane"
import { SquaredMinus as SqrdMns } from "@styled-icons/entypo/SquaredMinus"
import { SquaredPlus as SqrdPls } from "@styled-icons/entypo/SquaredPlus"
import { CheckmarkCircle2Outline as checkmark } from "@styled-icons/evaicons-outline/CheckmarkCircle2Outline"
import { EditOutline as _EditOutline } from "@styled-icons/evaicons-outline/EditOutline"
import { PhoneOutline as _PhoneOutline } from "@styled-icons/evaicons-outline/PhoneOutline"
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline"
import { Close as Clse } from "@styled-icons/evil/Close"
import { CashRegister } from "@styled-icons/fa-solid/CashRegister"
import { EllipsisH as _EllipsisH } from "@styled-icons/fa-solid/EllipsisH"
import { EllipsisV } from "@styled-icons/fa-solid/EllipsisV"
import { Eye as _Eye } from "@styled-icons/fa-solid/Eye"
import { EyeSlash as _EyeSlash } from "@styled-icons/fa-solid/EyeSlash"
import { Robot as _Robot } from "@styled-icons/fa-solid/Robot"
import { StoreAlt as _StoreAlt } from "@styled-icons/fa-solid/StoreAlt"
import { Warehouse as _Warehouse } from "@styled-icons/fa-solid/Warehouse"
import { ColumnEdit as _ColumnEdit } from "@styled-icons/fluentui-system-filled/ColumnEdit"
import { Warning as _Warning } from "@styled-icons/fluentui-system-filled/Warning"
import { BurstSale as _Sale } from "@styled-icons/foundation/BurstSale"
import { DocumentDuplicate as Dplct } from "@styled-icons/heroicons-outline/DocumentDuplicate"
import { NewTab as _NewTab } from "@styled-icons/icomoon/NewTab"
import { StatsDots } from "@styled-icons/icomoon/StatsDots"
import { ArrowForwardCircle as _NextCircle } from "@styled-icons/ionicons-outline/ArrowForwardCircle"
import { CloseCircle as clscrcl } from "@styled-icons/ionicons-outline/CloseCircle"
import { Add as Ad } from "@styled-icons/ionicons-sharp/Add"
import { Location as _Location } from "@styled-icons/ionicons-sharp/Location"
import { BakeryDining as _BakeryDining } from "@styled-icons/material-outlined/BakeryDining"
import { PictureAsPdf } from "@styled-icons/material-outlined/PictureAsPdf"
import { Restaurant as _Restaurant } from "@styled-icons/material-outlined/Restaurant"
import { ReceiptLong as _ReceiptLong } from "@styled-icons/material-rounded/ReceiptLong"
import { DeliveryDining as _DeliveryDining } from "@styled-icons/material/DeliveryDining"
import { DragIndicator as DrgHndl } from "@styled-icons/material/DragIndicator"
import { ExpandLess as ExLess } from "@styled-icons/material/ExpandLess"
import { ExpandMore as ExMore } from "@styled-icons/material/ExpandMore"
import { HideImage as _HideImage } from "@styled-icons/material/HideImage"
import { Image as _Image } from "@styled-icons/material/Image"
import { ManageSearch as _ManageSearch } from "@styled-icons/material/ManageSearch"
import { RemoveCircleOutline } from "@styled-icons/material/RemoveCircleOutline"
import { Verified as _Verified } from "@styled-icons/material/Verified"
import { Rocket as _Rocket } from "@styled-icons/octicons/Rocket"
import { CheckboxBlankCircle as _CheckboxBlankCircle } from "@styled-icons/remix-line/CheckboxBlankCircle"
import { CheckboxCircle as _CheckboxCircle } from "@styled-icons/remix-line/CheckboxCircle"
import { FileExcel2 } from "@styled-icons/remix-line/FileExcel2"
import { MailSend as Ml } from "@styled-icons/remix-line/MailSend"
import { MoneyEuroCircle as _Euro } from "@styled-icons/remix-line/MoneyEuroCircle"
import { Printer as Prntr } from "@styled-icons/remix-line/Printer"
import { ShoppingBasket2 } from "@styled-icons/remix-line/ShoppingBasket2"
import styled, { ThemedStyledProps, keyframes } from "styled-components"
import { Thermometer } from "styled-icons/boxicons-solid"
import { FileInvoice, ShippingFast } from "styled-icons/fa-solid"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapToColor(props: ThemedStyledProps<ColoredIconProps, any>) {
  return (
    (props.color && props.color) ||
    (props.primary && "var(--primary)") ||
    (props.darkgrey && "var(--dark)") ||
    (props.secondary && "var(--secondary)") ||
    (props.success && "var(--primary)") ||
    (props.danger && "var(--danger)") ||
    (props.grey && props.theme.grey) ||
    (props.lightgrey && "var(--light)") ||
    (props.white && "var(--white)") ||
    "white"
  )
}

export interface ColoredIconProps {
  color?: string
  primary?: boolean
  secondary?: boolean
  success?: boolean
  darkgrey?: boolean
  danger?: boolean
  grey?: boolean
  lightgrey?: boolean
  white?: boolean
}

interface HoverColorProps {
  hoverColor?: string
}

export const Checkmark = styled(checkmark)<ColoredIconProps>`
  color: ${mapToColor};
`

export const Circle = styled(Crcl)<ColoredIconProps>`
  color: ${mapToColor};
`

export const RemoveIcon = styled(RemoveCircleOutline)<ColoredIconProps>`
  color: ${mapToColor};
`
export const TrashIcon = styled(Trash)<ColoredIconProps>`
  color: ${mapToColor};
`

export const ImageAdd = styled(ImgAdd)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PlusCircle = styled(PlsCrcl)<ColoredIconProps>`
  color: ${mapToColor};
`
export const MinusCircle = styled(MnsCrcl)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Pencil = styled(Pncl)<ColoredIconProps>`
  color: ${mapToColor};
`
export const DragHandle = styled(DrgHndl)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Duplicate = styled(Dplct)<ColoredIconProps>`
  color: ${mapToColor};
`
export const SquaredPlus = styled(SqrdPls)<ColoredIconProps>`
  color: ${mapToColor};
`
export const SquaredMinus = styled(SqrdMns)<ColoredIconProps>`
  color: ${mapToColor};
`
export const InfoCircle = styled(InfCrcl)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Dashboard = styled(Dshbrd)<ColoredIconProps>`
  color: ${mapToColor};
`

export const Cabinet = styled(Cbnt)<ColoredIconProps>`
  color: ${mapToColor};
`

export const Store = styled(Str)<ColoredIconProps>`
  color: ${mapToColor};
`
export const VEllipsis = styled(EllipsisV)<ColoredIconProps>`
  color: ${mapToColor};
`

export const Logout = styled(LogOut)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Home = styled(Hme)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Stats = styled(StatsDots)<ColoredIconProps>`
  color: ${mapToColor};
`
export const FoodMenu = styled(FdMenu)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Settings = styled(Settings2Outline)<ColoredIconProps>`
  color: ${mapToColor};
`
export const LeftArrowNew = styled(LftArrow)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Calendar = styled(Cldr)<ColoredIconProps>`
  color: ${mapToColor};
`

export const ArrowDown = styled(DownArrow)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ArrowUp = styled(UpArrow)<ColoredIconProps>`
  color: ${mapToColor};
`

export const PayPalLogo = styled(Paypal)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CreditCardLogo = styled(CreditCard)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CashRegisterLogo = styled(CashRegister)<ColoredIconProps>`
  color: ${mapToColor};
`

export const ThermometherIcon = styled(Thermometer)<ColoredIconProps>`
  color: ${mapToColor};
`

export const InvoiceIcon = styled(FileInvoice)<ColoredIconProps>`
  color: ${mapToColor};
`

export const CircleFill = styled(CrclFll)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Printer = styled(Prntr)<ColoredIconProps & HoverColorProps>`
  color: ${mapToColor};

  &:hover {
    color: ${props => props.hoverColor === "success" && props.theme.primary};
  }
`
export const Mail = styled(Ml)<ColoredIconProps & HoverColorProps>`
  color: ${mapToColor};

  &:hover {
    color: ${props => props.hoverColor === "success" && props.theme.primary};
  }
`
export const Add = styled(Ad)<ColoredIconProps>`
  color: ${mapToColor};
`

export const ExpandMore = styled(ExMore)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ExpandLess = styled(ExLess)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CloseCircle = styled(clscrcl)<ColoredIconProps>`
  color: ${mapToColor};
`

export const ShoppingBasket = styled(ShoppingBasket2)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PdfDownload = styled(PictureAsPdf)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CsvDownload = styled(FileExcel2)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Close = styled(Clse)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Grid = styled(Grd)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ChevronRight = styled(ChvrnRight)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ChevronLeft = styled(ChvrnLeft)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ChevronDown = styled(ChvrnDown)<ColoredIconProps>`
  color: ${mapToColor};
`
export const EllipsisH = styled(_EllipsisH)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Search = styled(_Search)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ChatLeftFill = styled(_ChatLeftFill)<ColoredIconProps>`
  color: ${mapToColor};
`
export const EditOutline = styled(_EditOutline)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1.5em"};
  height: ${props => props.size || "1.5em"};
`
export const ChevronRightCircle = styled(_ChevronRightCircle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ChevronLeftCircle = styled(_ChevronLeftCircle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PhoneOutline = styled(_PhoneOutline)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Pickup = styled(_StoreAlt)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Delivery = styled(_DeliveryDining)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CheckboxCircle = styled(_CheckboxCircle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const CheckboxBlankCircle = styled(_CheckboxBlankCircle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PinFill = styled(_PinFill)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PinAngle = styled(_PinAngle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const PaperPlane = styled(_PaperPlane)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Rocket = styled(_Rocket)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Receipt = styled(_ReceiptLong)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Save = styled(_Save)<ColoredIconProps>`
  color: ${mapToColor};
`

export const User = styled(_User)<ColoredIconProps>`
  color: ${mapToColor};
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const Loader = styled(_Loader)<ColoredIconProps>`
  color: ${mapToColor};
  animation: ${rotate} 2s linear infinite;
`
export const Stopwatch = styled(_Stopwatch)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ToggleLeft = styled(_ToggleLeft)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ToggleRight = styled(_ToggleRight)<ColoredIconProps>`
  color: ${mapToColor};
`
export const HelpCircle = styled(_HelpCircle)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ClipboardPlus = styled(_ClipboardPlus)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Warning = styled(_Warning)<ColoredIconProps>`
  color: ${mapToColor};
`
export const Verified = styled(_Verified)<ColoredIconProps>`
  color: ${mapToColor};
`
export const ManageSearch = styled(_ManageSearch)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const LoaderAlt = styled(_LoaderAlt)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Location = styled(_Location)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ShoppingBags = styled(_ShoppingBags)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const DeliveryDining = styled(_DeliveryDining)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`

export const BakeryDining = styled(_BakeryDining)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Restaurant = styled(_Restaurant)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ShopWindow = styled(_ShopWindow)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`

export const HeartCircle = styled(_HeartCircle)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Rename = styled(_Rename)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Euro = styled(_Euro)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Star = styled(_Star)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Warehouse = styled(_Warehouse)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const StarFill = styled(_StarFill)<ColoredIconProps>`
  color: ${mapToColor};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Sale = styled(_Sale)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Key = styled(_Key)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const EyeSlash = styled(_EyeSlash)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`

export const Eye = styled(_Eye)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const NextCircle = styled(_NextCircle)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Dash = styled(_Dash)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Filter = styled(_Filter)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const HideImage = styled(_HideImage)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ImageIcon = styled(_Image)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ColumnEdit = styled(_ColumnEdit)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ArrowDownThin = styled(_ArrowDownThin)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const ArrowUpThin = styled(_ArrowUpThin)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Reset = styled(_Reset)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const NewTab = styled(_NewTab)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Bell = styled(_Bell)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const BellOff = styled(_BellOff)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
export const Robot = styled(_Robot)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`

export const Shipping = styled(ShippingFast)<ColoredIconProps>`
  color: ${props =>
    (props.color && props.color) ||
    (props.grey && props.theme.darkgrey) ||
    (props.primary && props.theme.primary) ||
    (props.secondary && props.theme.secondary) ||
    (props.success && props.theme.success) ||
    "currentColor"};
  width: ${props => props.size || "1em"};
  height: ${props => props.size || "1em"};
`
